import React from 'react';
import 'bootstrap-icons/font/bootstrap-icons.css';
import Headers from './Component/Header';
import Footer from './Component/Footer';
import carpet from './Component/product/carpet.jpeg';
import hardwood from './Component/product/hardwood.png';
import carpettile from './Component/product/carpettile.png';
import glasstile from './Component/product/glasstile.png';
import laminate from './Component/product/laminate.png';
import luxuryvinyl from './Component/product/luxuryvinyl.png';
import Tile from './Component/product/Tile.png';
import vinyl from './Component/product/vinyl.png';
import waterproof from './Component/product/waterproof.png';
import metaltile from './Component/product/metaltile.png';
import naturalstone from './Component/product/naturalstone.png';
import { useNavigate } from 'react-router-dom';
import s2 from './Component/s2.png';

function Product() {
  const Carpet = 'carpet';
  const Glasstile = 'glasstile';
  const Carpettile = 'carpet-tile';
  const Hardwood = 'hardwood';
  const Metaltile = 'metaltile';
  const Luxuryvinyl = 'luxuryvinyl';
  const Vinyl = 'vinyl';
  const TileS = 'Tile';
  const Naturalstone = 'naturalstone';
  const Waterproof = 'waterproof_Flooring';
  const MetalTile = 'metaltile';
  const Laminate = 'laminate'
  const Cork = 'cork'

  const navigate = useNavigate();
  const handlepropertyselect = (id) => {
    navigate(`/products/${id}`);
}
  return (
    <div>
      <div><Headers /> </div>
      <div className='container w-full'>
        <div className='text-center  p-4' >
          <span className='font-bold'>Shopping for new floors in the Cassville, MO area?</span> <br />
          <small className='justify'>BROWSE THE MOST EXTENSIVE LOCAL ONLINE FLOORING CATALOG</small>
        </div>
        <section className='row flex items-center justify-center'>
          <div className='col-6 col-md-3  items-center justify-content-center p-3 row '>
          <button onClick={() => handlepropertyselect(Carpet)}> <img src={carpet} alt="Shop for Carpet in Andalusia, AL area from Floor Store " title='Shop for Carpet in Andalusia, AL area from Floor Store ' class="d-block"  /></button>
            
            <p className='flex justify-content-center  font-bold' style={{color:'rgb(243, 127, 19)'}}>Carpet </p>
          </div>
          <div className='col-6 col-md-3 flex justify-content-center p-3 row'>
            <button onClick={() => handlepropertyselect(Carpettile)} ><img src={carpettile} alt="Los Angeles" title='Shop for Carpet Tile in Andalusia, AL area from Floor Store ' class="d-block" />   </button>
            <p className='flex justify-content-center text-[rgb(243, 127, 19)] font-bold' style={{color:'rgb(243, 127, 19)'}}>Carpet Tile</p>
          </div>
          <div className='col-6 col-md-3 flex items-center justify-content-center p-3 row'>
            <button onClick={() => handlepropertyselect(Hardwood)} ><img src={hardwood} alt="Los Angeles" title='Shop for HardWood in Andalusia, AL area from Floor Store ' class="d-block" />   </button>
            <p className='flex justify-content-center text-[rgb(243, 127, 19)] font-bold' style={{color:'rgb(243, 127, 19)'}}>HardWood</p>
          </div>
          <div className='col-6 col-md-3 flex items-center justify-content-center p-3 row'>
            <button onClick={() => handlepropertyselect(Laminate)}  ><img src={laminate} alt="Los Angeles" title='Shop for Laminate in Andalusia, AL area from Floor Store '  class="d-block" />   </button>
            <p className='flex justify-content-center text-[rgb(243, 127, 19)] font-bold' style={{color:'rgb(243, 127, 19)'}}>Laminate</p>
          </div>
          {/* <div className='col-6 col-md-3 flex justify-content-center p-3 row'>
            <button onClick={() => handlepropertyselect(TileS)} ><img src={Tile} alt="Los Angeles" title='Shop for Tile in Andalusia, AL area from Floor Store ' class="d-block" />   </button>
            <p className='flex justify-content-center text-[rgb(243, 127, 19)] font-bold' style={{color:'rgb(243, 127, 19)'}}>Tile</p>
          </div> */}
          <div className='col-6 col-md-3 flex justify-content-center p-3 row'>
            <button onClick={() => handlepropertyselect(Luxuryvinyl)} ><img src={luxuryvinyl} alt="Los Angeles" title='Shop for Luxury Vinyl in Andalusia, AL area from Floor Store '  class="d-block" />   </button>
            <p className='flex justify-content-center text-[rgb(243, 127, 19)] font-bold'style={{color:'rgb(243, 127, 19)'}} >Luxury Vinyl</p>
          </div>
          <div className='col-6 col-md-3 flex justify-content-center p-3 row'>
            <button onClick={() => handlepropertyselect(Waterproof)}  ><img src={waterproof} alt="Los Angeles" title='Shop for WaterProof in Andalusia, AL area from Floor Store ' class="d-block" />   </button>
            <p className='flex justify-content-center text-[rgb(243, 127, 19)] font-bold' style={{color:'rgb(243, 127, 19)'}} >Waterproof</p>
          </div>
          <div className='col-6 col-md-3 flex justify-content-center p-3 row'>
            <button onClick={() => handlepropertyselect(Vinyl)} ><img src={vinyl} alt="Los Angeles" title='Shop for Vinyl in Andalusia, AL area from Floor Store ' class="d-block" />   </button>
            <p className='flex justify-content-center text-[rgb(243, 127, 19)] font-bold' style={{color:'rgb(243, 127, 19)'}}>Vinyl</p>
          </div>
          {/* <div className='col-6 col-md-3 flex justify-content-center p-3 row'>
          <button onClick={() => handlepropertyselect()}> <img src={glasstile} alt="Shop for Carpet in Andalusia, AL area from Floor Store " title='Shop for Carpet in Andalusia, AL area from Floor Store ' class="d-block"  /></button>
            <p className='flex justify-content-center text-[rgb(243, 127, 19)] font-bold' style={{color:'rgb(243, 127, 19)'}}>Glass Tile</p>
          </div> */}
          <div className='col-6 col-md-3 flex justify-content-center p-3 row'>
          <button onClick={() => handlepropertyselect(Cork)}> <img src={naturalstone} alt="Shop for Carpet in Andalusia, AL area from Floor Store " title='Shop for Carpet in Andalusia, AL area from Floor Store ' class="d-block"  /></button>
            <p className='flex justify-content-center text-[rgb(243, 127, 19)] font-bold' style={{color:'rgb(243, 127, 19)'}}>Cork</p>
          </div>
          {/* <div className='col-6 col-md-3 flex justify-content-center p-3 row'>
          <button onClick={() => handlepropertyselect(MetalTile)}> <img src={metaltile} alt="Shop for Carpet in Andalusia, AL area from Floor Store " title='Shop for Carpet in Andalusia, AL area from Floor Store ' class="d-block"  /></button>
            <p className='flex justify-content-center text-[rgb(243, 127, 19)] font-bold' style={{color:'rgb(243, 127, 19)'}}>Metal Tile</p>
          </div> */}
          

        </section >
        <a href='/financing' ><img src={s2} alt="Los Angeles" class="d-block rounded p-4" /></a>
      </div>
      <div><Footer /> </div>
    </div>
  )
}

export default Product
