import React from 'react';
import 'bootstrap-icons/font/bootstrap-icons.css';
import Headers from './Component/Header';
import Footer from './Component/Footer';
import Request from './Component/requestEstimate';
import s2 from './Component/s2.png';

function Contact() {
  return (
    <div>
      <div><Headers /> </div>
      <div className='text-center font-bold p-4 container'>
        <span style={{ fontSize: '35px' }}>Contact us</span> <br />
        <small className='justify'>GET IN TOUCH WITH THE FLOORING PROFESSIONALS AT PACIFICONTRACT</small>
      </div>
      <div className='row '>
        <div className='col-md-8'><Request /> </div>
        <div className='col-md-3 p-4'>
          <div className="max-w-md mx-auto mt-8 p-8 bg-white rounded-md shadow-md">
            <div className="mb-4">
              <h2 className="text-2xl font-bold ">Store Info</h2>
              <p className="text-gray-600 text-2xl ">Address</p>
              <p className="text-gray-800">
              9 Jordan St. San Rafael, CA 94901        </p>
              <a href="https://www.google.com/maps/place/Pacific+Contract/@37.9681707,-122.5282297,17z/data=!4m15!1m8!3m7!1s0x808599f81c5f6e01:0xc00c65e1f339f07f!2sPacific+Contract!8m2!3d37.9681665!4d-122.5256548!10e1!16s%2Fg%2F1tdc5f_7!3m5!1s0x808599f81c5f6e01:0xc00c65e1f339f07f!8m2!3d37.9681665!4d-122.5256548!16s%2Fg%2F1tdc5f_7?hl=en&entry=ttu" target="_blank" class="btn btn-warning  btn-lg active d-flex justify-content-center" role="button" style={{ borderRadius: '15px' }} rel="noreferrer">Get directions</a>

            </div>


            <div>
              <p className="text-gray-600">Phone</p>
              <p className="text-gray-800">(415) 218-0103</p>
            </div>

            <div className="mb-4">
              <a href="tel:+14152180103" class="btn btn-warning  btn-lg active d-flex justify-content-center" role="button" style={{ borderRadius: '15px' }} >Call us</a>

            </div>

            <div className="mb-4">
              <p className="text-gray-600">Hours</p>
              <p className="text-gray-800">
                Mon-Fri 9am - 5pm<br />
                Sat-Sun Closed
              </p>
            </div>

            <div>
              <a href="/free-estimate" class="btn btn-warning  btn-lg active d-flex justify-content-center" role="button" style={{  borderRadius: '15px' }} >Request an estimate</a>

            </div>
          </div>
        </div>

      </div>
      <div class="container py-5">
        <a href='/financing'><img src={s2} alt="Los Angeles" class="d-block rounded" /></a>

      </div>
      <div class="container">
        <div class="map-responsive">
        <iframe src="https://www.google.com/maps/embed?pb=!1m14!1m8!1m3!1d3145.383837896004!2d-122.5282297!3d37.9681707!3m2!1i1024!2i768!4f13.1!3m3!1m2!1s0x808599f81c5f6e01%3A0xc00c65e1f339f07f!2sPacific%20Contract!5e0!3m2!1sen!2sin!4v1710157098873!5m2!1sen!2sin" title="example" width="100%" height="450" frameborder="0"  allowfullscreen />
        </div>
      </div>
      <div><Footer /> </div>
    </div>
  )
}

export default Contact
