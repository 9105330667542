import React, { useState } from 'react'
import logo from './home/logo.png';
import 'bootstrap/dist/js/bootstrap.bundle.min.js'; // Import Bootstrap JS
function Header() {
	const [menuOpen, setMenuOpen] = useState(false);

	const onToggleMenu = () => {
		setMenuOpen(true);
	};
	const onCloseMenu = () => {
		setMenuOpen(false); // Close the menu
	};

	return (
		<div className='font-sans'>
			<div className=' text-center pt-1.5 text-white  text-md font-bold ' style={{ backgroundColor: 'rgb(243, 127, 19)' }}><span><p className='hidden md:flex md:font-[10px] text-center items-center justify-center font-bold'><span className='font-bold'>9 Jordan St. San Rafael, CA 94901 </span>  <a href="tel:+14152180103" className='text-black font-semibold'>
				<i className="bi bi-telephone pl-5 text-white font-bold"> (415) 218-0103</i>
			</a></p></span></div>
			<div class="bg-white ">
			<div className="flex item-center  md:hidden justify-center pb-3" >
							<a href="/" class="d-block " ><img src={logo}  alt="Los Angeles" /> </a>


						</div>
					<div class="flex items-center lg:order-2 pb-2 px-3">
					
						<button onClick={onToggleMenu}
							name={menuOpen ? 'close' : 'menu'}
							className="text-3xl cursor-pointer md:hidden">
							<span class="sr-only">Open main menu</span>
							<svg class="w-6 h-6" fill="currentColor" viewBox="0 0 20 20" xmlns="http://www.w3.org/2000/svg"><path fill-rule="evenodd" d="M3 5a1 1 0 011-1h12a1 1 0 110 2H4a1 1 0 01-1-1zM3 10a1 1 0 011-1h12a1 1 0 110 2H4a1 1 0 01-1-1zM3 15a1 1 0 011-1h12a1 1 0 110 2H4a1 1 0 01-1-1z" clip-rule="evenodd"></path></svg>
							<svg class="hidden w-6 h-6" fill="currentColor" viewBox="0 0 20 20" xmlns="http://www.w3.org/2000/svg"><path fill-rule="evenodd" d="M4.293 4.293a1 1 0 011.414 0L10 8.586l4.293-4.293a1 1 0 111.414 1.414L11.414 10l4.293 4.293a1 1 0 01-1.414 1.414L10 11.414l-4.293 4.293a1 1 0 01-1.414-1.414L8.586 10 4.293 5.707a1 1 0 010-1.414z" clip-rule="evenodd"></path></svg>

						</button>

					</div>
				<div class="container px-2">


					<div class="flex items-center justify-center  pb-2">

						<div class="hidden md:flex md:items-center">					</div>

						<div className=" hidden md:flex md:item-center justify-center " >
							<a href="/" class="d-block "  ><img src={logo} alt="Los Angeles"  /> </a>


						</div>
						<div
						className={`nav-links duration-500 md:static   text-black  pt-2  left-0 ${menuOpen ? 'top-[9%] bg-white relative' : 'top-[-100%] items-center justify-content-center absolute'
							} md:w-auto w-full flex  `}
					>
						<ul className="flex md:flex-row flex-col md:items-center  md:justify-content-center p-2  border-slate-700 font-sans">

							<li className={`md:hidden ${menuOpen ? 'md:hidden' : 'md:hidden'}`}>
								<button class="text-black   font-semibold hover:text-purple-600 " onClick={onCloseMenu}>
									<i class="bi bi-x-square-fill"></i>
								</button>
							</li>
							{/* <li>
								<a href="/specials" class="text-black   btn px-3">Specials</a>
							</li> */}
							<li className="nav-item">
								<div className="btn-group dropend">
									<button
										type="button"
										className="btn  dropdown-toggle font-semibold text-black"
										data-bs-toggle="dropdown"
										aria-expanded="false"
									>
										<span className='font-bold'> Products</span>
									</button>
									<ul className="dropdown-menu">
										<li>
											<a href="/products" class=" dropdown-item hover:text-green-900 ">All Products</a>
										</li>
										<li>
											<a href="/products/carpet" class=" dropdown-item hover:text-green-900 ">Carpet</a>
										</li>
										<li>
											<a href="/products/hardwood" class=" dropdown-item hover:text-green-900 ">Hardwood</a>
										</li>
										<li>
											<a href="/products/laminate" class=" dropdown-item hover:text-green-900 ">Laminate</a>
										</li>
										<li>
											<a href="/products/carpet-tile" class=" dropdown-item hover:text-green-900 ">Carpet-tile</a>
										</li>
										<li>
											<a href="/products/luxuryvinyl" class=" dropdown-item hover:text-green-900 ">luxury Vinyl</a>
										</li>
										<li>
											<a href="/products/waterproof_Flooring" class=" dropdown-item hover:text-green-900 ">Waterproof Flooring</a>
										</li>
										<li>
											<a href="/products/vinyl" class=" dropdown-item hover:text-green-900 ">Vinyl</a>
										</li>
										<li>
											<a href="/products/cork" class=" dropdown-item hover:text-green-900 ">Cork</a>
										</li>
										<li>
											<a href="/custom-area-rugs" class=" dropdown-item hover:text-red-700 ">Area rugs</a>
										</li>


									</ul>
								</div>
							</li>
							<li>
											<a href="/flooring-services" class=" text-black  btn px-3"> <span className='font-bold'> Services</span></a>
										</li>
							{/* <li className="nav-item">
								<div className="btn-group dropend">
									<button
										type="button"
										className="btn  dropdown-toggle font-semibold text-black"
										data-bs-toggle="dropdown"
										aria-expanded="false"
									>
										Services
									</button>
									<ul className="dropdown-menu">
										<li>
											<a href="/flooring-services" class=" dropdown-item hover:text-green-900 ">All Services</a>
										</li>
										<li>
											<a href="/bathroom-remodeling" class=" dropdown-item hover:text-green-900 ">Bathroom Remodeling</a>
										</li>
										<li>
											<a href="/blinds-n-shutters" class=" dropdown-item hover:text-green-900 ">Blinds & Shutters</a>
										</li>
										<li>
											<a href="/builder-n-contractor-program" class=" dropdown-item hover:text-green-900 ">Builder & Contractor Program</a>
										</li>
										<li>
											<a href="/design-services" class=" dropdown-item hover:text-green-900 ">Design Services</a>
										</li>
									</ul>
								</div>
							</li> */}
							<li>
								<a href="/reviews" class="text-black btn px-3 font-bold"><span className='font-bold'> Reviews</span></a>
							</li>
							<li>
								<a href="/financing" class="text-black  btn px-3"><span className='font-bold'> Financing</span></a>
							</li>
							<li>
								<a href="/inspiration" class="text-black  btn px-3"><span className='font-bold'> Inspiration</span></a>
							</li>
							<li>
								<a href="/about-us" class="text-black  btn px-3"><span className='font-bold'> About</span></a>
							</li>
							<li>
								<a href="/contact" class="text-black  btn px-3"><span className='font-bold '> Contact</span></a>
							</li>
							<li className={`md:hidden ${menuOpen ? 'md:hidden' : 'md:hidden'}`}>
								<a href="https://pacificontract.myhelcim.com/hosted/?token=c4982d85a0d5020b3f938b" class=" text-black  btn px-3"><span className='font-bold '>Pay Invoices</span></a>

							</li>
							<li className={`md:hidden ${menuOpen ? 'md:hidden' : 'md:hidden'}`}>
								<a href="/free-estimate" class=" text-black  btn px-3"><span className='font-bold '>Request an Estimate</span></a>

							</li>


						</ul>
					</div>


						<div class="hidden  md:flex md:items-center px-3">
							<div><a href="/free-estimate" class=" text-white  btn btn-warning  px-3">Request Estimate</a></div>
						</div>
						<div class="hidden  md:flex md:items-center px-3">
							<div><a href="https://pacificontract.myhelcim.com/hosted/?token=c4982d85a0d5020b3f938b" class=" text-white  btn btn-warning  px-3">Pay Invoices</a></div>
						</div>


					</div>
					



					
				</div>




			</div>


		</div>
	)
}

export default Header
