import React from 'react';
import 'bootstrap-icons/font/bootstrap-icons.css';
import Headers from './Component/Header';
import Footer from './Component/Footer';
import Request from './Component/requestEstimate';
import about from './Component/about.png';
import about1 from './Component/about1.png';
import brand1 from './Component/home/brand1.png';
import awards from './Component/awards.png';
import hh1 from './Component/hh1.png';
import hh2 from './Component/hh2.png';

function About() {
  return (
    <div>
      <div><Headers /> </div>
      <div className='container p-4'>
        <div className='text-center font-bold p-4'>
          <span style={{ fontSize: '35px' }}>About PacifiContract</span> <br />
          <small className='justify'>GET TO KNOW YOUR SAN RAFAEL, CA AREA FLOORING EXPERTS</small>
        </div>
        <section className='container'>
          <div class="row" >
            <div class="col-sm-12 col-md-6 col-lg-6 col-xl-6">
              <img src={about} alt="Los Angeles" class="d-block" style={{ borderRadius: '10%' }} />
            </div>
            <div class="col-sm-12 col-md-6 col-lg-6 col-xl-6" style={{
            }}>
             <div className='jumbotron' >
							<p class="text-2xl font-bold ">Family owned & operated</p>
							<div className='row'>
								<div class="col-md-12">
									<div class="card-box-c foo" style={{ border: '2px solid #A9B6D3', borderRadius: '15px', padding: '20px' }}>
										<div class="card-body-c align-self-center">
											<p class="content-c"
											>PacifiContract is family owned and operated. We have excellent customer service. We specialize in being able to service all of your flooring needs. Hardwood, carpet, vinyl sheet and floating floors are our specialty and we are perfectionists. Quality work and customer satisfaction is what we strive for. Come in or call and schedule an appointment today.
											</p>
											<p class="lead">
												<a href="/about-us" class="btn btn-warning  btn-lg active" role="button" style={{ borderRadius: '8px' }}>Contact Us</a>
											</p>

										</div>
									</div>
								</div>

							</div>
						</div>

            </div>
          </div>
        </section >
      </div>
	  <img
					src={awards}
					alt="Flooring, furniture and hardware needs"
					className="mx-auto py-3"
				/>
      
      {/* <section class="bg-red-900">
				<div className='container'>
					<div class="row flex p-3 ">
					<div class="col-md-4 col-sm-12 p-1 col-12">
					<div className='p-4 text-white text-center ' >
							<p class=" font-bold text-[25px]">
 Why should you choose us?</p>
							<div className='row'>
								<div class="col-md-12">
									<div class="card-box-c foo" style={{ border: '2px solid #A9B6D3', borderRadius: '15px', padding: '20px' }}>
										<div class="card-body-c align-self-center">
											<p class="content-c">
We offer the highest quality products and services with the largest selection in the area. Contact us today for a free in-home estimate and let us turn your house into a home!											</p>
										<p class="lead">
												<a href="/products" class="btn btn-warning  btn-lg active" role="button" style={{ borderRadius: '8px' }}>BROWSE OUR CATALOG</a>
											</p>

										</div>
									</div>
								</div>

							</div>
						</div>
						</div>
						<div class="col-md-4 col-sm-12 p-1 col-12 rounded" >
							<div class=" foo bg-red-700 text-white">
							<img
					src={hh1}
					alt="Flooring, furniture and hardware needs"
					className="mx-auto "
				/>
								<div class="card-body-c align-self-center p-4 ">
									<p class="content-c ">
									The Andalusia, AL area trusts The Floor Store (AL) for all of their flooring needs.
									</p>
									<a href='/reviews'> <div className='btn btn-warning  w-100'> Our Reviews</div> </a>
								</div>
							</div>

						</div>
						<div class="col-md-4 col-sm-12 p-1 col-12 rounded" >
							<div class=" foo bg-red-700 text-white">
							<img
					src={hh2}
					alt="Flooring, furniture and hardware needs"
					className="mx-auto "
				/>
								<div class="card-body-c align-self-center p-4 ">
									<p class="content-c ">
									Request your accurate and professional in-home flooring estimate.
									</p>
									<a href='/free-estimate'> <div className='btn btn-warning  w-100'> Request Estimate</div> </a>
								</div>
							</div>

						</div>
						
						
					</div>
				</div>

			</section> */}
      {/* <img src={about1} alt="Los Angeles" class="d-block w-full" /> */}

      <div><Request /></div>

      <section className='p-4 container border-t-2 py-2'>
        <div className='text-center font-bold'>
          <span style={{ fontSize: '25px' }}>Featured brands & technologies</span>
        </div>
        <section>
					<div >
							<img
								src={brand1}
								alt="brands"
								className="mx-auto "
							/>
						</div>
						
			</section>

      </section>
      <div><Footer /> </div>
    </div>
  )
}

export default About
