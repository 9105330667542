import React, { useState } from 'react'
import 'bootstrap-icons/font/bootstrap-icons.css';
import logo from './Component/home/logo.png';
import 'bootstrap/dist/js/bootstrap.bundle.min.js';
import Footer from './Component/Footer';
import Request from './Component/requestEstimate';
import home2 from './Component//home/home1.jpg';
import home5 from './Component//home/home2.jpg';
import home3 from './Component//home/home3.jpg';
import brand1 from './Component/home/brand1.png';
import i1 from './Component/i1.png';
import carpet from './Component/product/h1.png';
import hardwood from './Component/product/h2.png';
import laminate from './Component/product/h3.png';
import Tile from './Component/product/h4.png';
import s2 from './Component/s2.png';
import hh1 from './Component/hh1.png';
import hh2 from './Component/hh2.png';
import Header from './Component/Header';
import inspired from './Component/inspired.png'


function Home() {
	const [menuOpen, setMenuOpen] = useState(false);

	const onToggleMenu = () => {
		setMenuOpen(true);
	};
	const onCloseMenu = () => {
		setMenuOpen(false); // Close the menu
	};

	return (
		<div >

			<div className="md:hero-wrap md:hero-wrap-2 block md:hidden "><Header /></div>
			<section className="md:hero-wrap md:hero-wrap-2 block md:hidden ">
				<div >
					<img
						src={home2}
						alt="brands"
						className="mx-auto w-full "
					/>
				</div>
			</section>
			<section className="md:hero-wrap md:hero-wrap-2   md:block hidden " style={{ backgroundImage: `url(${home2})`, height: '100%' }}>
				<div className="overlay h-full " >
					<div className='font-sans md:pb-[425px]' style={{ background: 'rgba(0, 0, 0, 0.4)' }} >
						
						<div class=" ">
						<div className=' text-center  text-white  text-sm ' ><span><p className='hidden md:flex md:font-[10px] text-center items-center justify-center '><span className='font-bold'>9 Jordan St. San Rafael, CA 94901 </span>  <a href="tel:+14152180103" className='text-white font-semibold'>
							<i className="bi bi-telephone pl-5 text-white"> (415) 218-0103</i>
						</a></p></span></div>
							<div className="flex item-center  md:hidden justify-center pb-3" >
								<a href="/" class="d-block " ><img src={logo}   alt="Los Angeles" /> </a>


							</div>
							<div class="flex items-center lg:order-2 pb-2 px-3">

								<button onClick={onToggleMenu}
									name={menuOpen ? 'close' : 'menu'}
									className="text-3xl cursor-pointer md:hidden">
									<span class="sr-only">Open main menu</span>
									<svg class="w-6 h-6" fill="currentColor" viewBox="0 0 20 20" xmlns="http://www.w3.org/2000/svg"><path fill-rule="evenodd" d="M3 5a1 1 0 011-1h12a1 1 0 110 2H4a1 1 0 01-1-1zM3 10a1 1 0 011-1h12a1 1 0 110 2H4a1 1 0 01-1-1zM3 15a1 1 0 011-1h12a1 1 0 110 2H4a1 1 0 01-1-1z" clip-rule="evenodd"></path></svg>
									<svg class="hidden w-6 h-6" fill="currentColor" viewBox="0 0 20 20" xmlns="http://www.w3.org/2000/svg"><path fill-rule="evenodd" d="M4.293 4.293a1 1 0 011.414 0L10 8.586l4.293-4.293a1 1 0 111.414 1.414L11.414 10l4.293 4.293a1 1 0 01-1.414 1.414L10 11.414l-4.293 4.293a1 1 0 01-1.414-1.414L8.586 10 4.293 5.707a1 1 0 010-1.414z" clip-rule="evenodd"></path></svg>

								</button>

							</div>
							<div class=" px-2">


								<div class="flex items-center justify-center  pb-2">

									<div class="hidden md:flex md:items-center">					</div>

									<div className=" hidden md:flex md:item-center justify-center " >
										<a href="/" class="d-block " ><img src={logo}  alt="Los Angeles" /> </a>


									</div>
									<div
										className={`nav-links duration-500 md:static   pt-2 text-white    left-0 ${menuOpen ? 'top-[9%]  relative' : 'top-[-100%] items-center justify-content-center absolute'
											} md:w-auto w-full flex  `}
									>
										<ul className="flex md:flex-row flex-col md:items-center  md:justify-content-center p-2  border-slate-700 font-sans">

											<li className={`md:hidden ${menuOpen ? 'md:hidden' : 'md:hidden'}`}>
												<button class="text-white   font-semibold hover:text-purple-600 " onClick={onCloseMenu}>
													<i class="bi bi-x-square-fill"></i>
												</button>
											</li>
											{/* <li>
								<a href="/specials" class="text-white   btn px-3">Specials</a>
							</li> */}
											<li className="nav-item">
								<div className="btn-group dropend">
									<button
										type="button"
										className="btn  dropdown-toggle font-semibold text-white"
										data-bs-toggle="dropdown"
										aria-expanded="false"
									>
										<span className='font-bold'> Products</span>
									</button>
									<ul className="dropdown-menu">
										<li>
											<a href="/products" class=" dropdown-item hover:text-green-900 ">All Products</a>
										</li>
										<li>
											<a href="/products/carpet" class=" dropdown-item hover:text-green-900 ">Carpet</a>
										</li>
										<li>
											<a href="/products/hardwood" class=" dropdown-item hover:text-green-900 ">Hardwood</a>
										</li>
										<li>
											<a href="/products/laminate" class=" dropdown-item hover:text-green-900 ">Laminate</a>
										</li>
										<li>
											<a href="/products/carpet-tile" class=" dropdown-item hover:text-green-900 ">Carpet-tile</a>
										</li>
										<li>
											<a href="/products/waterproof_Flooring" class=" dropdown-item hover:text-green-900 ">Waterproof Flooring</a>
										</li>
										<li>
											<a href="/products/luxuryvinyl" class=" dropdown-item hover:text-green-900 ">luxury Vinyl</a>
										</li>
										<li>
											<a href="/products/vinyl" class=" dropdown-item hover:text-green-900 ">Vinyl</a>
										</li>
										<li>
											<a href="/products/cork" class=" dropdown-item hover:text-green-900 ">Cork</a>
										</li>
										<li>
											<a href="/custom-area-rugs" class=" dropdown-item hover:text-red-700 ">Area rugs</a>
										</li>


									</ul>
								</div>
							</li>
											<li>
												<a href="/flooring-services" class=" text-white  btn px-3"> <span className='font-bold'> Services</span></a>
											</li>
											{/* <li className="nav-item">
								<div className="btn-group dropend">
									<button
										type="button"
										className="btn  dropdown-toggle font-semibold text-white"
										data-bs-toggle="dropdown"
										aria-expanded="false"
									>
										Services
									</button>
									<ul className="dropdown-menu">
										<li>
											<a href="/flooring-services" class=" dropdown-item hover:text-green-900 ">All Services</a>
										</li>
										<li>
											<a href="/bathroom-remodeling" class=" dropdown-item hover:text-green-900 ">Bathroom Remodeling</a>
										</li>
										<li>
											<a href="/blinds-n-shutters" class=" dropdown-item hover:text-green-900 ">Blinds & Shutters</a>
										</li>
										<li>
											<a href="/builder-n-contractor-program" class=" dropdown-item hover:text-green-900 ">Builder & Contractor Program</a>
										</li>
										<li>
											<a href="/design-services" class=" dropdown-item hover:text-green-900 ">Design Services</a>
										</li>
									</ul>
								</div>
							</li> */}
											<li>
												<a href="/reviews" class="text-white btn px-3 font-bold"><span className='font-bold'> Reviews</span></a>
											</li>
											<li>
												<a href="/financing" class="text-white  btn px-3"><span className='font-bold'> Financing</span></a>
											</li>
											<li>
												<a href="/inspiration" class="text-white  btn px-3"><span className='font-bold'> Inspiration</span></a>
											</li>
											<li>
												<a href="/about-us" class="text-white  btn px-3"><span className='font-bold'> About </span></a>
											</li>
											<li>
												<a href="/contact" class="text-white  btn px-3"><span className='font-bold '> Contact</span></a>
											</li>
											<li className={`md:hidden ${menuOpen ? 'md:hidden' : 'md:hidden'}`}>
								<a href="https://pacificontract.myhelcim.com/hosted/?token=c4982d85a0d5020b3f938b" class=" text-black  btn px-3"><span className='font-bold '>Pay Invoices</span></a>

							</li>
							<li className={`md:hidden ${menuOpen ? 'md:hidden' : 'md:hidden'}`}>
								<a href="/free-estimate" class=" text-black  btn px-3"><span className='font-bold '>Request an Estimate</span></a>

							</li>
											


										</ul>
									</div>


									<div class="hidden  md:flex md:items-center px-3">
										<div><a href="/free-estimate" class=" text-white  btn btn-warning  px-3">Request Estimate</a></div>
									</div>
									<div class="hidden  md:flex md:items-center px-3">
										<div><a href="https://pacificontract.myhelcim.com/hosted/?token=c4982d85a0d5020b3f938b" class=" text-white  btn btn-warning  px-3">Pay Invoices</a></div>
									</div>


								</div>





							</div>




						</div>


					</div>
				</div>

			</section>

			<section className='  p-4' >
				<div className='container text-center'>
					<h1 class=" text-3xl   leading-none tracking-tight text-gray-900 md:text-4xl lg:text-4xl "> Looking for new floors in the San Rafael, CA area?</h1>
					<p class="mb-6 text-lg font-normal text-gray-500 lg:text-xl md:px-16 xl:px-48 warning:text-gray-400"> SHOP OUR EXTENSIVE FLOORING CATALOG</p>
					<div class="row flex justify-content-center ">
						<div class="col-sm-6 col-lg-4 col-12 p-2 justify-center bg-white " style={{ height: '100%' }}>
							<section className="hero-wrap hero-wrap-2" style={{ backgroundImage: `url(${carpet})`, height: '100%' }}>
								<div className="overlay h-full" >
									<div className="container ">
										<div className="row no-gutters slider-text align-items-end p-3 justify-content-center" >
											<div className=" ftco-animate mb-2 text-center py-4 text-white border-3 border-s-white " style={{ background: 'rgba(0, 0, 0, 0.5)' }}>
												<h2 className=" "><div className='text-white  capitalize font-extrabold pb-3'>Carpet</div></h2>

												<a href='/products/carpet' > <div className='btn btn-warning '>Browse Carpet</div> </a>
											</div>
										</div>
									</div>
								</div>

							</section>
						</div>
						<div class="col-sm-6 col-lg-4 col-12 p-2 justify-center bg-white " style={{ height: '100%' }}>
							<section className="hero-wrap hero-wrap-2" style={{ backgroundImage: `url(${Tile})`, height: '100%' }}>
								<div className="overlay h-full" >
									<div className="container ">
										<div className="row no-gutters slider-text align-items-end p-3 justify-content-center" >
											<div className=" ftco-animate mb-2 text-center py-4 text-white border-3 border-s-white " style={{ background: 'rgba(0, 0, 0, 0.5)' }}>
												<h2 className=" "><div className='text-white  capitalize font-extrabold pb-3'>Carpet Tile</div></h2>

												<a href='/products/carpet-tile' > <div className='btn btn-warning '>Browse Carpet-tile</div> </a>
											</div>
										</div>
									</div>
								</div>

							</section>
						</div>
						<div class="col-sm-6 col-lg-4 col-12 p-2 justify-center bg-white " style={{ height: '100%' }}>
							<section className="hero-wrap hero-wrap-2" style={{ backgroundImage: `url(${hardwood})`, height: '100%' }}>
								<div className="overlay h-full" >
									<div className="container ">
										<div className="row no-gutters slider-text align-items-end p-3 justify-content-center" >
											<div className=" ftco-animate mb-2 text-center py-4 text-white border-3 border-s-white " style={{ background: 'rgba(0, 0, 0, 0.5)' }}>
												<h2 className=" "><div className='text-white  capitalize font-extrabold pb-3'>Hardwood</div></h2>

												<a href='/products/hardwood' > <div className='btn btn-warning '>Browse Hardwood</div> </a>
											</div>
										</div>
									</div>
								</div>

							</section>
						</div>
						<div class="col-sm-6 col-lg-4 col-12 p-2  justify-center bg-white " style={{ height: '100%' }}>
							<section className="hero-wrap hero-wrap-2" style={{ backgroundImage: `url(${laminate})`, height: '100%' }}>
								<div className="overlay h-full" >
									<div className="container ">
										<div className="row no-gutters slider-text align-items-end p-3 justify-content-center" >
											<div className=" ftco-animate mb-2 text-center py-4 text-white border-3 border-s-white " style={{ background: 'rgba(0, 0, 0, 0.5)' }}>
												<h2 className=" "><div className='text-white  capitalize font-extrabold pb-3'>luxuryVinyl</div></h2>

												<a href='/products/luxuryvinyl' > <div className='btn btn-warning '>Browse luxuryvinyl</div> </a>
											</div>
										</div>
									</div>
								</div>

							</section>
						</div>
						<div class="col-sm-6 col-lg-4 col-12 p-2  justify-center bg-white " style={{ height: '100%' }}>
							<section className="hero-wrap hero-wrap-2" style={{ backgroundImage: `url(${home2})`, height: '100%' }}>
								<div className="overlay h-full" >
									<div className="container ">
										<div className="row no-gutters slider-text align-items-end p-3 justify-content-center" >
											<div className=" ftco-animate mb-2 text-center py-4 text-white border-3 border-s-white " style={{ background: 'rgba(0, 0, 0, 0.5)' }}>
												<h2 className=" "><div className='text-white  capitalize font-extrabold pb-3'>Laminate</div></h2>

												<a href='/products/laminate' > <div className='btn btn-warning '>Browse Laminate</div> </a>
											</div>
										</div>
									</div>
								</div>

							</section>
						</div>
						<div class="col-sm-6 col-lg-4 col-12 p-2  justify-center bg-white " style={{ height: '100%' }}>
							<section className="hero-wrap hero-wrap-2" style={{ backgroundImage: `url(${hardwood})`, height: '100%' }}>
								<div className="overlay h-full" >
									<div className="container ">
										<div className="row no-gutters slider-text align-items-end p-3 justify-content-center" >
											<div className=" ftco-animate mb-2 text-center py-4 text-white border-3 border-s-white " style={{ background: 'rgba(0, 0, 0, 0.5)' }}>
												<h2 className=" "><div className='text-white  capitalize font-extrabold pb-3'>Cork</div></h2>

												<a href='/products/cork' > <div className='btn btn-warning '>Browse Cork</div> </a>
											</div>
										</div>
									</div>
								</div>

							</section>
						</div>

					</div>
					<a href='/products' className='flex items-center justify-center'> <div className='btn w-[25%] btn-light'> <span className='no-underline font-bold hover:text-slate-900'>View All Products </span></div> </a>

				</div>



			</section>
			
			{/* <a href='/free-estimate'>
				<img
					src={home9}
					alt="Flooring, furniture and hardware needs"
					className="mx-auto "
				/>
			</a> */}
			<section >
			<div class="container pt-3">
				<a href='/financing'><img src={s2} alt="Los Angeles" class="d-block rounded" /></a>

			</div>
			<div class=" pt-3">
				<img src={inspired} alt="Los Angeles" class="w-[100%]" />

			</div>


			</section>
			<section class="">
				<div className='container'>
					<div class="row flex p-3 ">
					<div class="col-sm-12 col-md-6 col-lg-6 col-xl-6 flex items-center justify-center p-2" style={{
						paddingInline: '5% 10%'
					}}>
							<div className='p-2 text-black text-center ' >
								<p class=" font-bold  text-2xl">Family owned & operated</p>
								<div className='row'>
									<div class="col-md-12">
										<div class="card-box-c foo" style={{ border: '2px solid #A9B6D3', borderRadius: '15px' }}>
											<div class="card-body-c align-self-center">
												<p class="content-c">PacifiContract is family owned and operated. We have excellent customer service. We specialize in being able to service all of your flooring needs. Hardwood, carpet, vinyl sheet and floating floors are our specialty and we are perfectionists. Quality work and customer satisfaction is what we strive for. Come in or call and schedule an appointment today.</p><p class="lead">
													<a href="/about-us" class="btn btn-warning  btn-lg active" role="button" style={{ borderRadius: '8px' }}>Explore More About Us</a>
												</p>

											</div>
										</div>
									</div>

								</div>
							</div>
						</div>
						<div class="col-sm-12 col-md-6 col-lg-6 col-xl-6 p-2">
						<img src={home5} alt="Los Angeles" class="d-block" style={{ borderRadius: '6%' }} />
					</div>
						
						


					</div>
				</div>

			</section>

			
			<section className='container'>
				<div class="row p-3" >
					<div class="col-sm-12 col-md-6 col-lg-6 col-xl-6 p-2">
						<img src={home3} alt="Los Angeles" class="d-block" style={{ borderRadius: '6%' }} />
					</div>
					<div class="col-sm-12 col-md-6 col-lg-6 col-xl-6 flex items-center justify-center p-2" style={{
						paddingInline: '5% 10%'
					}}>
						<div className='jumbotron' >
							<p class=" font-semibold text-2xl">Why should you choose us?</p>
							<div className='row'>
								<div class="col-md-12">
									<div class="card-box-c foo  w-full" style={{ border: '2px solid #A9B6D3', borderRadius: '15px', padding: '20px' }}>
										<div class="card-body-c align-self-center">
											<p class="content-c py-3"><ul>
											<li className='py-2'>Commitment to excellence</li>
											<li className='py-2'>Everyday low prices</li>
											<li className='py-2'>Highest quality products</li>
											<li className='py-2'>Next day appointments available</li>
											<li className='py-2'>Excellent relationships with suppliers</li>
												<li className='py-2'>Licensed installers</li>
											</ul></p>
											<p class="lead">
												<a href="/free-estimate" class="btn btn-warning  btn-lg active" role="button" style={{ borderRadius: '8px' }}>Request an Estimate</a>
											</p>
										</div>
									</div>
								</div>

							</div>
						</div>

					</div>

				</div>
			</section >
			<section className='container'>
				<div class="row " >
					<div class="col-sm-12 col-md-4 col-lg-4 col-xl-4 p-2">
					<section className="hero-wrap hero-wrap-2" style={{ backgroundImage: `url(${Tile})`, height: '100%' }}>
								<div className="overlay h-full" >
									<div className="container ">
										<div className="row no-gutters slider-text align-items-end p-3 justify-content-center" >
											<div className=" ftco-animate mb-2 text-center py-[40px] text-white border-3 border-s-white " style={{ background: 'rgba(0, 0, 0, 0.5)' }}>
												<h2 className=" "><div className='text-white  capitalize font-bold pb-3'>Reviews</div></h2>
												<p class="content-c">The San Rafael, CA area trusts PacifiContract for all of their flooring needs.</p>

												<a href='/reviews' > <div className='btn  btn-light'>READ OUR REVIEWS</div> </a>
											</div>
										</div>
									</div>
								</div>

							</section>
					</div>
					<div class="col-sm-12 col-md-4 col-lg-4 col-xl-4 p-2">
					<section className="hero-wrap hero-wrap-2" style={{ backgroundImage: `url(${Tile})`, height: '100%' }}>
								<div className="overlay h-full" >
									<div className="container ">
										<div className="row no-gutters slider-text align-items-end p-3 justify-content-center" >
											<div className=" ftco-animate mb-2 text-center py-[40px] text-white border-3 border-s-white " style={{ background: 'rgba(0, 0, 0, 0.5)' }}>
												<h2 className=" "><div className='text-white  capitalize font-bold pb-3'>Request a consultation</div></h2>
												<p class="content-c">Request your accurate and professional in-home flooring estimate.</p>

												<a href='/free-estimate' > <div className='btn  btn-light'>SCHEDULE NOW</div> </a>
											</div>
										</div>
									</div>
								</div>

							</section>
					</div>
					<div class="col-sm-12 col-md-4 col-lg-4 col-xl-4 p-2">
					<section className="hero-wrap hero-wrap-2" style={{ backgroundImage: `url(${Tile})`, height: '100%' }}>
								<div className="overlay h-full" >
									<div className="container ">
										<div className="row no-gutters slider-text align-items-end p-3 justify-content-center" >
											<div className=" ftco-animate mb-2 text-center py-[40px] text-white border-3 border-s-white " style={{ background: 'rgba(0, 0, 0, 0.5)' }}>
												<h2 className=" "><div className='text-white  capitalize font-bold pb-3 '>Services</div></h2>
												<p class="content-c">Our professional flooring installers are ready to help you with your next project.</p>

												<a href='/flooring-services' > <div className='btn  btn-light '>SEE WHAT WE OFFER</div> </a>
											</div>
										</div>
									</div>
								</div>

							</section>
					</div>
					

				</div>
			</section >
			
			
			
			
			
			<div><Request /></div>
			<section className='p-4 container border-t-2 py-2'>
				<div className='text-center font-bold'>
					<span style={{ fontSize: '25px' }}>Featured brands & technologies</span>

				</div>
				<section>
					<div >
						<img
							src={brand1}
							alt="brands"
							className="mx-auto "
						/>
					</div>
				</section>

			</section>
			<div><Footer /></div>


		</div>
	);
}

export default Home;
