import React from 'react';
import 'bootstrap-icons/font/bootstrap-icons.css';
import Headers from './Component/Header';
import Footer from './Component/Footer';
import Request from './Component/requestEstimate';
import s11 from './Component/s11.png';
import s12 from './Component/s12.png';

function Contact() {
    return (
        <div>
            <div><Headers /> </div>
            <section className="hero-wrap hero-wrap-2" style={{ backgroundImage: `url(${s11})` }} data-stellar-background-ratio="0.5">
                <div className="overlay" style={{ background: 'rgba(0, 0, 0, 0.3)' }}>
                    <div className="container">
                        <div className="row no-gutters slider-text align-items-end justify-content-center">
                            <div className="col-md-9 ftco-animate mb-2 text-center p-5 text-white ">
                                <h2 className=" "><div className='text-white  capitalize'>Custom area rugs</div></h2>
                                <p class="mb-2 text-lg font-normal text-white lg:text-xl sm:px-16 xl:px-48 " >Wide variety of styles </p>

                                <a href='/free-estimate'> <div className='btn btn-warning  '>Request an Estimate</div> </a>
                            </div>
                        </div>
                    </div>
                </div>

            </section>
            <div className='row  justify-center items-center'>
                <div className='col-md-6  '><Request /> </div>
                <div className='col-md-6 '>
                    <div className=' p-5' >
                        <h2 class=" font-bold">Why custom area rugs should be part of your decor</h2>
                        <div className='row'>
                            <div class="col-md-12">
                                <div class="card-box-c foo" style={{ borderRadius: '5px' }}>
                                    <div class="card-body-c align-self-center">
                                        <p class="content-c">
                                        Custom area rugs are common additions to floors such as hardwood, laminate, tile, or luxury vinyl. They can even be placed over broadloom carpeting. These rugs pull a room's decor together by adding texture and color. Scatter rugs also provide a lot of functions, such as noise insulation. They may be small, but they have a large impact.</p>
                                    </div>
                                </div>
                            </div>

                        </div>
                        <h4 class=" font-bold">Custom size rugs: Getting just what you want</h4>
                        <div className='row'>
                            <div class="col-md-12">
                                <div class="card-box-c foo" style={{ borderRadius: '5px' }}>
                                    <div class="card-body-c align-self-center">
                                        <p class="content-c">Today's rooms aren't perfect squares; they're often oddly shaped with door sills, baseboards, bay windows, closets, and moldings with which to contend. In addition, stock rugs are only available in standard colors, sizes, and shapes. Keep in mind that when you get custom area rugs, you get precisely what you want, when you want it.</p>

                                        <p class="content-c">
                                        For instance, you may see something you love, but the size may be wrong, the color might not be suitable, or it may be in a fiber that doesn't meet your needs. Come into our custom rugs department, where we will make your dream a reality!                                        </p>

                                    </div>
                                </div>
                            </div>

                        </div>
                        {/* <p class="lead">
                            <a href="/free-estimate" class="btn btn-warning  btn-lg active" role="button" style={{ borderRadius: '15px' }}>Request an Estimate</a>
                        </p>       */}
                          </div>

                </div>

            </div>
            <div className='container'>
            <h3 class=" font-bold pt-3 text-center">Separate and define</h3>
            <p class="content-c ">With today's layouts, it's not unusual to find one big room that includes both the living and dining areas. You can create a cozy seating area with a separate dining space with coordinating but different rugs in each area.</p>
            <p class="content-c">It's also not unusual to need an extra room, whether for a home library, office, etc.; that's easily done by sectioning off a corner and defining it with an area rug.</p>
            <h3 class=" font-bold pt-4 text-center">Preserving money and sentiment</h3>
            <p class="content-c">There's often a lot of leftover material with a new carpet installation; don't throw it out! Instead, use the excess carpet to make custom size rugs. Sometimes you also have a family heirloom that has too much sentimental value to part with or something that's just too pricey to replace, such as Oriental or Persian; cut around that damage and create a rug.</p>
            </div>
            <div className='p-5'>
            <div className='row  justify-center items-center'>
                <div className='col-md-6  '><img
								src={s12}
								alt="brands"
								className="mx-auto "
							/> </div>
                <div className='col-md-6 '>
                    <div className=' mt-10 pr-[20px] p-2' >
                    <h3 class=" font-bold">How to place custom area rugs</h3>
                        <div className='row'>
                            <div class="col-md-12">
                                <div class="card-box-c foo" style={{ borderRadius: '5px' }}>
                                    <div class="card-body-c align-self-center">

                                        <p class="content-c">
                                        Area rugs must be large enough to fit under major furniture, such as sofas, while still having 12 to 18-inches of bare floor visible. If that's not possible, the rug can anchor the front legs while showing 12 to 18-inches of the floor. Custom area rugs in dining rooms should be large enough to fit under the table and the chairs. You can place two matching runners on each side of the bed in the bedroom.</p>
                                    </div>
                                </div>
                            </div>

                        </div>
                        <h3 class=" font-bold">Visit us for custom area rugs today</h3>
                        <div className='row'>
                            <div class="col-md-12">
                                <div class="card-box-c foo" style={{ borderRadius: '5px' }}>
                                    <div class="card-body-c align-self-center">
                                        <p class="content-c">Bring your ideas to us, and we'll do the rest. No muss, no fuss, and, of course, you'll also get a free quote. The PacifiContract showroom is in San Rafael, CA and we work on residential and commercial projects in Marin County, CA, San Francisco, CA, East Bay, CA, Sonoma County, CA, && San Jose, CA. Stop by today for your custom area rugs.</p>

                                    </div>
                                </div>
                            </div>

                        </div>
                        <p class="lead">
                            <a href="/free-estimate" class="btn btn-warning  btn-lg active" role="button" style={{ borderRadius: '15px' }}>Request an Estimate</a>
                        </p>        </div>

                </div>

            </div>
            </div>


            <div><Footer /> </div>
        </div>
    )
}

export default Contact
