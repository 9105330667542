import React from 'react';
import 'bootstrap-icons/font/bootstrap-icons.css';
import Headers from './Component/Header';
import Footer from './Component/Footer';
import brand1 from './Component/f7.png';

function About() {
  return (
    <div>
      <div><Headers /> </div>
      <div className='text-center font-bold p-4'>
        <span style={{ fontSize: '35px' }}>Financing</span> <br />
        <small className='justify'>CALL US OR STOP IN TODAY TO EXPLORE ALL OF OUR AVAILABLE FINANCING OPTIONS</small>
      </div>
      <div class="flex items-center justify-center ">
            <a href="https://www.synchrony.com/financing/home/partners?intcmp=na-homenetwork_cardholder-footer-data_reason-internal&uniqueId=A4A3EBF64AFDCFFC2134A5FA83B439C36152B7CA3C2EDF51&client=SynchronyHOME" target="_blank" rel="noreferrer">
              <img src={brand1} alt="Los Angeles" />
            </a>
          </div>
          <div className='flex items-center justify-center pb-5'>
          <a href='/contact' className=' btn  btn-warning text-white text-xl '> Contact Us </a>

          </div>
      {/* <section className='p-4 container border-t-2 py-2'>
				<div className='text-center font-bold'>
					<span style={{ fontSize: '25px' }}>Our trusted lending partners</span>
				</div>
				<section className='pt-4'>
					<div >
							<img
								src={brand1}
								alt="brands"
								className="mx-auto "
							/>
						</div>
			</section>

			</section> */}
      <div><Footer /> </div>
    </div>
  )
}

export default About
